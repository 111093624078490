<script lang="ts" setup>
import { match } from 'ts-pattern';

const colorMode = useColorMode();

const cycleColorMode = () =>
    (colorMode.value = match<'light' | 'dark' | 'auto', 'light' | 'dark'>(colorMode.value)
        .with('light', () => 'dark')
        .with('dark', () => 'light')
        .otherwise(() => 'light'));

const icon = computed(() =>
    match(colorMode.value)
        .with('light', () => 'prime:sun')
        .with('dark', () => 'prime:moon')
        .otherwise(() => 'prime:sun'),
);
</script>

<template>
    <button
        type="button"
        class="rounded-full p-1.5 w-9 h-9 hover:bg-neutral-200 dark:hover:bg-neutral-700"
        @click="cycleColorMode"
    >
        <Icon :name="icon" class="text-neutral-400 w-6 h-6" />
    </button>
</template>
